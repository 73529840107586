import { Component, AfterViewInit, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RoleConst } from 'src/app/role.constants';
import { UserService } from 'src/app/services/user.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {
  @ViewChild('mainMenu', {static: false}) mainMenu: ElementRef;
  loadingIndicator = true;
  loadingMessage = 'Loading...';
  blockModal: boolean;
  racfGroup = [];

  hasMEADADMN = false;
  hasMEADSIAM = false;
  hasMMTRNG = false;
  hasSTNSADMN = false;
  hasREPORTVIEW = false;
  hasSTNMANAGER = false;
  showReportView = true;
  gpmsId: string;
  
  constructor(private router: Router,
    private usrService: UserService,
    private http: HttpClient,
    private auth: AuthenticationService,
    private renderer: Renderer2) {
  }

  ngOnInit() {
    const main = this;
    const httpOptions = {
      withCredentials: true
    }

    const email = this.usrService.getUser().email;
    const authCheckUrl = environment.auth_service + 'api/';
    this.http.get(authCheckUrl + 'authUsers?email=' + email, httpOptions)
      .subscribe((data: any) => {
        if (data) {
          data.forEach(e => {
            this.racfGroup.push(e.racfGroup);

            if (RoleConst.SUPERADMIN === e.racfGroup) {
              this.hasMEADADMN = true;
              this.hasMEADSIAM = true;
              this.hasMMTRNG = true;
              this.hasSTNSADMN = true;
              this.hasREPORTVIEW = true;
            } else if (RoleConst.MEADADMN === e.racfGroup) {
              this.hasMEADADMN = true;
              this.hasREPORTVIEW = true;
            } else if (RoleConst.MEADSIAM === e.racfGroup) {
              this.hasMMTRNG = true;
              this.hasMEADSIAM = true;
              this.hasREPORTVIEW = true;
            } else if (RoleConst.MMTRNG === e.racfGroup) {
              this.hasMMTRNG = true;
              this.hasREPORTVIEW = true;
            } else if (RoleConst.STNSADMN === e.racfGroup) {
              this.hasSTNSADMN = true;
              this.hasREPORTVIEW = true;
            } else if (RoleConst.STNMANAGER === e.racfGroup) {
              this.hasSTNMANAGER = true;
              // this.hasREPORTVIEW = true;
            }
          });

          if (this.racfGroup.length === 0) {
            this.router.navigate(['unauthorized'], { skipLocationChange: true });
            return;
          }

        }

        setTimeout(() => {
          main.loadingIndicator = false;
          main.renderer.setStyle(main.mainMenu.nativeElement, 'visibility', 'visible');
        }, 0);
      });

    this.http.get(authCheckUrl + 'gpmsId?email=' + email, httpOptions)
      .subscribe((data:any) => {
        if(data){
          this.gpmsId = data.gpmsId;
        }
      });
  }

  ngAfterViewInit() {
    setTimeout(() => document.querySelector("input").focus(), 500);
  }

  NavigateTo(NavKey) {
    this.auth.clearUserActivity();
    switch (NavKey) {
      case "1":
        if (this.hasSTNSADMN) {
          this.router.navigate(['gpms', 'femn', this.gpmsId], { skipLocationChange: true });
        }
        break;
      case "2":
        if (this.hasMEADADMN) {
          this.router.navigate(['gpms', 'msmn', this.gpmsId], { skipLocationChange: true });
        }
        break;
      case "3":
        if (this.hasMEADADMN) {
          this.router.navigate(['gpms', 'maint_admin', this.gpmsId], { skipLocationChange: true });
        }
        break;
      case "4":
        if (this.hasMEADSIAM) {
          this.router.navigate(['gpms', 'mst0', this.gpmsId], { skipLocationChange: true });
        }
        break;
      case '5':
        if (this.hasMEADSIAM) {
          this.router.navigate(['gpms', 'msot', this.gpmsId], { skipLocationChange: true });
        }
        break;
      case '6':
        if (this.hasMMTRNG) {
          this.router.navigate(['gpms', 'msdi', this.gpmsId], { skipLocationChange: true });
        }
        break;
      case '7':
        if (this.hasMEADADMN) {
          this.router.navigate(['gpms', 'datAdminWeb', this.gpmsId], { skipLocationChange: true });
        }
        break;
      case '8':
        if (this.hasSTNMANAGER) {
          this.router.navigate(['gpms', 'datAdminWeb', this.gpmsId], { skipLocationChange: true });
        }
        break;
      case 'R':
        if (this.hasREPORTVIEW) {
          this.openReportView();
        }
        break;
      case "L":
        this.router.navigate(['/logout']);
        break;
    }
    return;
  }

  openReportView() {
    window.open(environment.reportView + '?id=' + this.gpmsId.toLowerCase() + '&racf='
      + this.getMainRacfGroup() + '&token=' + this.usrService.getUser().accessToken, '_blank');
  }

  private getMainRacfGroup(): string {
    if (this.racfGroup.indexOf(RoleConst.SUPERADMIN) !== -1) {
      return RoleConst.SUPERADMIN;
    }

    if (this.racfGroup.indexOf(RoleConst.MEADADMN) !== -1) {
      return RoleConst.MEADADMN;
    }

    if (this.racfGroup.indexOf(RoleConst.STNSADMN) !== -1) {
      return RoleConst.STNSADMN;
    }

    if (this.racfGroup.indexOf(RoleConst.MEADSIAM) !== -1) {
      return RoleConst.MEADSIAM;
    }

    return RoleConst.MMTRNG;
  }
}
