/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./exit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./exit.component";
import * as i3 from "@angular/common/http";
import * as i4 from "../../services/user.service";
import * as i5 from "@angular/router";
var styles_ExitComponent = [i0.styles];
var RenderType_ExitComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExitComponent, data: {} });
export { RenderType_ExitComponent as RenderType_ExitComponent };
export function View_ExitComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col-lg-12 no-padd main-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Logging out...\n"]))], null, null); }
export function View_ExitComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-exit", [], null, null, null, View_ExitComponent_0, RenderType_ExitComponent)), i1.ɵdid(1, 114688, null, 0, i2.ExitComponent, [i3.HttpClient, i4.UserService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExitComponentNgFactory = i1.ɵccf("app-exit", i2.ExitComponent, View_ExitComponent_Host_0, {}, {}, []);
export { ExitComponentNgFactory as ExitComponentNgFactory };
