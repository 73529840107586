import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MenuComponent } from './components/menu/menu.component';

import { ExternalComponent } from './components/gpms/external/external.component';
import { ExitComponent } from './components/exit/exit.component'

import { AuthGuardService } from './services/auth-guard.service'

import { BannerComponent} from './components/banner/banner.component'
import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [
  { path: '', component: MenuComponent , canActivate: [AuthGuardService] },
  { path: 'gpms/:id/:gpmsid', component: ExternalComponent , canActivate: [AuthGuardService] }, 
  { path: 'banner', component: BannerComponent, canActivate: [AuthGuardService]},
  { path: 'logout', component: ExitComponent , canActivate: [AuthGuardService]  },
  { path: 'unauthorized', component: NotFoundComponent},
  { path: '**', redirectTo: 'unauthorized'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
