<div *ngIf="loadingIndicator" class="loading-indicator">{{loadingMessage}}</div>
<div #mainMenu class="mf-holder">
  <app-header></app-header>
  <div class="col-lg-12 no-padd main-title">
    <span>═════════════════════════════════════════════════════════════════════</span><br>
    <span>GPMS HOME MENU</span><br>
    <span>═════════════════════════════════════════════════════════════════════</span><br>
  </div>
  <app-banner></app-banner>

  <div class="col-lg-12 no-padd">
    <span class="line columns"> </span>
  </div>
  <ul class="col-lg-12 no-padd">
    <li [ngClass]="{'choices-main-disable': !hasSTNSADMN }" class="col3 choices-main" (click)="NavigateTo('1')"><span
        class="menu-num">1.</span> GPMS DB2</li>
    <li [ngClass]="{'choices-main-disable': !hasMEADADMN }" class="col3 choices-main" (click)="NavigateTo('2')"><span
        class="menu-num">2.</span> GPMS-MF</li>
    <li [ngClass]="{'choices-main-disable': !hasMEADADMN }" class="col3 choices-main" (click)="NavigateTo('3')"><span
        class="menu-num">3.</span> TSO - Maintenance Administration</li>
    <li [ngClass]="{'choices-main-disable': !hasMEADSIAM }" class="col3 choices-main" (click)="NavigateTo('4')"><span
        class="menu-num">4.</span> MST0 - USAir Training</li>
    <li [ngClass]="{'choices-main-disable': !hasMEADSIAM }" class="col3 choices-main" (click)="NavigateTo('5')"><span
        class="menu-num">5.</span> MSOT - Overtime Tracking</li>
    <li [ngClass]="{'choices-main-disable': !hasMMTRNG }" class="col3 choices-main" (click)="NavigateTo('6')"><span
        class="menu-num">6.</span> MSDI - Employee Training</li>
    <li [ngClass]="{'choices-main-disable': !hasMEADADMN }" class="col3 choices-main" (click)="NavigateTo('7')"><span
        class="menu-num">7.</span> DAT Admin</li>
    <li [ngClass]="{'choices-main-disable': !hasSTNMANAGER }" class="col3 choices-main" (click)="NavigateTo('8')"><span
        class="menu-num">8.</span> Station Manager</li>
    <li *ngIf="showReportView" [ngClass]="{'choices-main-disable': !hasREPORTVIEW }" class="col3 choices-main" (click)="NavigateTo('R')"><span
        class="menu-num">R.</span> Report View</li>
    <li class="col3 choices-main" [routerLink]="['/logout']"><span class="menu-num">L.</span> Logout</li>
  </ul>

  <div class="col-lg-12 no-padd">
    <span class="col3">Select and press ENTER : </span>
    <input type="text" class="mf-input" maxlength="2" oninput="this.value = this.value.toUpperCase()"
      (keydown.enter)="NavigateTo($event.target.value)">
  </div>

  <app-footer></app-footer>
</div>
