import { Component, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-external',
  templateUrl: './external.component.html',
  styleUrls: ['./external.component.scss']
})
export class ExternalComponent implements AfterViewInit {
  @ViewChild('iframe', {static: false}) iframe: ElementRef;
  url: string;
  loadingIndicator = true;
  loadingMessage = 'Loading...';

  constructor(private route: ActivatedRoute, private renderer: Renderer2) {
    const id = this.route.snapshot.paramMap.get('id');
    const gpmsid = this.route.snapshot.paramMap.get('gpmsid');
    this.url = environment[id] + '?gpmsid=' + gpmsid;
  }

  ngAfterViewInit() {
    const main = this;
    this.iframe.nativeElement.addEventListener('load', () => {
      main.loadingIndicator = false;
      main.renderer.setStyle(main.iframe.nativeElement, 'visibility', 'visible');
      main.iframe.nativeElement.focus();
    });

    this.iframe.nativeElement.setAttribute('src', this.url);
  }
}
