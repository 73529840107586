/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./external.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./external.component";
import * as i4 from "@angular/router";
var styles_ExternalComponent = [i0.styles];
var RenderType_ExternalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExternalComponent, data: {} });
export { RenderType_ExternalComponent as RenderType_ExternalComponent };
function View_ExternalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loading-indicator"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loadingMessage; _ck(_v, 1, 0, currVal_0); }); }
export function View_ExternalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { iframe: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExternalComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["iframe", 1]], null, 2, "iframe", [["id", "iframe"], ["sandbox", "allow-forms allow-modals allow-popups allow-same-origin allow-scripts allow-top-navigation allow-downloads"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please use a browser that support iframes."]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loadingIndicator; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ExternalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-external", [], null, null, null, View_ExternalComponent_0, RenderType_ExternalComponent)), i1.ɵdid(1, 4243456, null, 0, i3.ExternalComponent, [i4.ActivatedRoute, i1.Renderer2], null, null)], null, null); }
var ExternalComponentNgFactory = i1.ɵccf("app-external", i3.ExternalComponent, View_ExternalComponent_Host_0, {}, {}, []);
export { ExternalComponentNgFactory as ExternalComponentNgFactory };
