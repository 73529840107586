const home = 'https://gpms-dev1.aa.com';
const internalHome = 'https://gpms-dev1.aa.com';
const mfdb2 = 'https://mfdb2.gpms-dev1.aa.com/';
const tsoWeb = 'https://tso-web.gpms-dev1.aa.com';
const authService = 'https://auth.gpms-dev1.aa.com/';
const reportView = 'https://report-view.gpms-dev1.aa.com/reportTool';
const datAdminWeb = 'https://dat-admin-web.gpms-dev1.aa.com';

const msmn = mfdb2 + '#/menu/MSMN/%20';
const femn = mfdb2 + '#/menu/FEMN/%20';
const msot = mfdb2 + '#/menu/MSOT/%20';
const mst0 = mfdb2 + '#/menu/MST0/%20';
const msdi = mfdb2 + '#/menu/MSDI/%20';

const ping = 10;

export const environment = {
  production: false,
  maint_admin : tsoWeb,
  auth_service : authService,
  home,
  internal_home: internalHome,
  msmn,
  femn,
  msot,
  mst0,
  msdi,
  gpms : mfdb2,
  ping,
  reportView,
  datAdminWeb
};
