import { Component, HostListener, OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';
import { interval, Subscription, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthGuardService } from './services/auth-guard.service';

export enum KEY_CODE {
  ESC = 27,//ESC KEY
  ENTER = 13//ENTER KEY
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'gpms-home';

  constructor(private auth: AuthenticationService, private router: Router) { }
  
  ngOnInit(): void {
    //notify activity
    this.auth.callPing();

    const source = interval(5 * 60 * 1000);
    this.auth.sessionCheck = source.subscribe(() => {
      console.log('session check');
      if (!this.auth.isAuthenticated()) {
        console.log('session timeout, refresing page to get token');
        location.reload();
      }

      setTimeout(() => this.auth.callInactivityCheck(),0);
    });
    
    this.auth.userActivityTimeout();
    this.auth.userInactive.subscribe(() => {
      console.log('notify user is active');
      this.auth.callPing();
    });
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    (window as any).onhelp = function() { return false };
    this.auth.resetActivity();
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.auth.resetActivity();
  }
}
