import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, pipe } from 'rxjs';
import { catchError, map, tap, first } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { UserService } from './user.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private http: HttpClient, private auth: AuthenticationService, private usrService: UserService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const auth_service = environment.auth_service;
    const home = environment.home;
    const internalHome = environment.internal_home;

    /*
    if ((home !== internalHome) && (window.top.location.href.indexOf(internalHome) >= 0)) {
      console.log('Redirecting to proper URL.');
      window.top.location.href = home;
      return;
    }
    */

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }),
      withCredentials: true
    }

    if (this.auth.isAuthenticated()) return true;
    //skip api hit if token is valid
    
    const observable = this.http.get<any>(auth_service + 'user', httpOptions).pipe(
      map(result => {
        if (result) {
          const userAuthentication = result.userAuthentication;
          const tokenValue = result.details.tokenValue;
          const details = userAuthentication.details;
          const identities = details.identities[0];

          this.usrService.getUser().accessToken = tokenValue;
          this.usrService.getUser().fname = identities.idpUserInfo.attributes.Firstname;
          this.usrService.getUser().lname = identities.idpUserInfo.attributes.Lastname;
          this.usrService.getUser().name = identities.idpUserInfo.attributes.name;
          this.usrService.getUser().locale = identities.idpUserInfo.attributes.locale;
          this.usrService.getUser().idpemail = identities.idpUserInfo.attributes.email;
          this.usrService.getUser().user = userAuthentication.name;
          this.usrService.getUser().email = details.email;
          return true;
        } else {
          this.usrService.clearUser();
          console.log('not authenticated');
          return false;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        console.log(err);
        this.usrService.clearUser();
        //window.open(auth_service + '?r=' + home,'_self');
        window.open(auth_service + '?r=' + window.location.href,'_self');
        return of(false);
      })
    );
    return observable;
  }
}
